<template>
    <div class="cell auto">

    <div class="cell small-4" style="background-color:lightgrey">
        <!-- <input v-model="teamname" /><button v-on:click="changeteam">Update</button> -->
<br/>
        <div>
            <autocomplete :search="searchDropdownData" :get-result-value="getResultValue" placeholder="find teams" @submit="changeTeam"></autocomplete>
        </div>
        <div>
            <dropdown ocname="selectsports" @sport_values="setSport"  ocdefaultvalue="soccer" :ocoptions="$store.state.sports" :ocnoitems="999" :occloseonselect="true" :ocmultiselect="false"  :ocshowlistonstart="false" :ocheightoflist="350"></dropdown>
          
        </div>
        
   
    <br/>
        <kit :sport="selectedsport" :teamname="team[0]" :key="team[0] + 1"></kit>
        <kit :sport="selectedsport" :vmagnify="2" :hmagnify="2" :teamname="team[0]" :key="team[0] + 2"></kit>
        <kit :sport="selectedsport" :vmagnify="4" :hmagnify="4" :teamname="team[0]" :key="team[0] + 3" ></kit>

        <br/><br/>Current Team Name = {{team[0]}}

    </div>

</div>
</template>

<script>
import axios from "axios";
import autocomplete from "@trevoreyre/autocomplete-vue";
import kit from '../components/image/kit.vue'
import Dropdown from "../components/navigation/dropdown.vue";

    export default {
        data() {  return {
            teamname: '',
            team: ["Wolfsburg"],
            selectedsport: 'Soccer'
        }},
        components: {
    kit,
    autocomplete,
    Dropdown
},
        methods: {

            setSport(val) {

    
                let desc = val.Description;
                this.selectedsport = desc;

            },

                changeTeam: function (result) {
                    this.team[0] = result.event;
                    this.$forceUpdate();
   
                },
    //get full data for search results
    searchDropdownData(input) {
      if (input.length < 3) {
        return [];
      }

      var URL = process.env.VUE_APP_WEBAPI + "FeatureTables.svc/SearchItems";

      return new Promise((resolve) => {
        axios
          .get(URL, {
            params: {
              desc: input, //parseInt(this.id), //can get multiple banner sizes by passing the banner number into the array
              noitems: 50, //parseInt(this.noitems), // does nothing at the moment 18/01/2019
              wildcard: true,
              sportid: -1 
            },
          })
          .then(
            (response) => {
              //any BO elements
              let data = JSON.parse(response.data)[0];

              if (Object.keys(data).length > 1) {
                data = data.BO;
              } else {
                data = [];
              }
              return resolve(data);
            },

            (error) => {
              console.log(error);
            }
          );
      });
    },

    getResultValue(result) {
      return result.event;
    }

        }   
    }
</script>

<style lang="scss" scoped>

</style>