<template>
    <div>

            <div v-if="flagloaded && country != ''">
                <div @mouseover="mouseQuadrant" style="margin-right:8px;margin-left:6px" class="qflagmedium"  :class="{tooltipno: hints}" >
                  
                    <img :src="'/assets/images/flags/svg/' + country + '.svg'" />
                    <span class="tooltiptext" :class="{'tooltip-right': $store.state.rightside}">{{country}}</span>
                </div> 
                
            </div>
          
            <div v-if="kitloaded">
              
         
                    <svg version="1.1" :id="kd.a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :width="hsize" :height="vsize" viewBox="584.97 685.911 45 55" enable-background="new 584.97 685.911 45 55" xml:space="preserve">
                   

                    <!-- the defs below are the different patterns that can be used to fill the shapes that make up the shirt -->
                    <defs>

                        <pattern :id="'0.25V'+imgid" patternUnits="userSpaceOnUse" width="6.298" height="20">
                            <rect x="0" y="0" width="1.5745" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                            <rect x="1.5745" y="0" width="4.7235" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                            <!-- <rect x="6.298" y="0" width="1.5745" height="100%" :fill="kd.c" stroke-width="0"></rect>
                            <rect x="7.8725" y="0" width="4.7235" height="100%" :fill="kd.b" stroke-width="0"></rect> -->
                        </pattern>
                        <pattern :id="'V'+imgid" patternUnits="userSpaceOnUse" width="7.2" height="100%">
                            <rect id="firststripe" x="0" y="0" width="3.6" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                            <rect id="secondstripe" x="3.6" y="0" width="7.2" height="100%" :fill="kd.c" fill-opacity="1"></rect>
              
                        </pattern>
                        <!-- <pattern :id="'V3'+imgid" patternUnits="userSpaceOnUse"  width="10" height="10">
                            <line x1="0" y1="0" x2="0" y2="10" :stroke="kd.b" :stroke-width="5" />
                            <line x1="10" y1="0" x2="10" y2="10" :stroke="kd.c" :stroke-width="5" />
                        </pattern> -->
                        <pattern :id="'VV'+imgid"  width="12.596" height="100%">
                            <rect id="firststripe" x="0" y="0" width="6.298" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                            <rect id="secondstripe" x="6.298" y="0" width="6.298" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                        </pattern>
                        <pattern :id="'VV3'+imgid"  width="12.596" height="100%">
                            <rect id="firststripe" x="0" y="0" width="6.298" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                            <rect id="secondstripe" x="6.298" y="0" width="6.298" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                        </pattern>
                        <pattern :id="'VV2'+imgid"  patternUnits="userSpaceOnUse" width="7.2" height="100%">
                            <rect id="firststripe" x="0" y="0" width="50%" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                            <rect id="secondstripe" x="3.6" y="0" width="50%" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                        </pattern>
                        <pattern :id="'VV4'+imgid"  width="7.2" height="100%">
                            <rect id="firststripe" x="0" y="0" width="6.298" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                            <rect id="secondstripe" x="6.298" y="0" width="6.298" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                        </pattern>
                        <pattern :id="'HH'+imgid"  width="100%"  height="12.598" >
                            <rect id="firsthoop" x="0" y="0" width="100%" height="50%" :fill="kd.b" fill-opacity="1"></rect>
                            <rect id="secondhoop" x="0" y="6.298" width="100%" height="50%" :fill="kd.c" fill-opacity="1"></rect>
                        </pattern>



                        <pattern :id="'0.25H'+imgid" patternTransform="rotate(90)" patternUnits="userSpaceOnUse" width="6.298" height="20">
                            <rect x="0" y="0" width="1.5745" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                            <rect x="1.5745" y="0" width="4.7235" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                        </pattern>
                        <pattern :id="'H'+imgid" patternTransform="rotate(90)" patternUnits="userSpaceOnUse" width="6.298" height="100%">
                            <rect id="firststripe" x="0" y="0" width="3.149" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                            <rect id="secondstripe" x="3.149" y="0" width="6.298" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                        </pattern>
                        <pattern :id="'2H'+imgid" patternTransform="rotate(90)" patternUnits="userSpaceOnUse" width="6.298" height="100%">
                            <rect id="firststripe" x="0" y="0" width="3.149" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                            <rect id="secondstripe" x="3.149" y="0" width="6.298" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                        </pattern>
                        <pattern :id="'h4'+imgid" patternTransform="rotate(90)" patternUnits="userSpaceOnUse" width="10" height="10">
                            <line x1="0" y1="0" x2="0" y2="10" :stroke="kd.b" stroke-width="10" />
                            <line x1="10" y1="0" x2="10" y2="10" :stroke="kd.c" stroke-width="10" />
                        </pattern>
                       
                        <pattern :id="'HH3'+imgid" patternTransform="rotate(90)" patternUnits="userSpaceOnUse" width="12.596" height="100%" >
                            <rect id="firsthoop" x="0" y="0" width="4.198" height="100%" :fill="kd.b" fill-opacity="1"></rect>
                            <rect id="secondhoop" x="4.198" y="0" width="4.198" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                            <rect id="thirdhoop" x="8.3973" y="0" width="4.198" height="100%" :fill="kd.c" fill-opacity="1"></rect>
                        </pattern>
                        <pattern :id="'HH2'+imgid"  width="100%"  height="12.598" >
                            <rect id="firsthoop" x="0" y="0" width="100%" height="50%" :fill="kd.c" fill-opacity="1"></rect>
                            <rect id="secondhoop" x="0" y="6.298" width="100%" height="50%" :fill="kd.b" fill-opacity="1"></rect>
                        </pattern>
                       

                        <pattern :id="'4C'+imgid"  patternUnits="userSpaceOnUse" width="12.596" height="12.596">
                            <rect x="0" y="0" width="6.298" height="50%" :fill="kd.b" fill-opacity="1"></rect>
                            <rect x="6.298" y="0" width="6.298" height="50%" :fill="kd.c" fill-opacity="1"></rect>
                            <rect x="0" y="6.298" width="6.298" height="50%" :fill="kd.c" fill-opacity="1"></rect>
                            <rect x="6.298" y="6.298" width="6.298" height="50%" :fill="kd.b" fill-opacity="1"></rect> -->
                        </pattern>

                        <pattern :id="'DRA'+imgid" patternUnits="objectBoundingBox" width="12.596" height="12.596">
                            <polygon points="12.596,0 0,12.596 12.596,12.596" :fill="kd.c" fill-opacity="1" />
                            <polygon points="0,12.596 12.596,0 12.596,12.596"  :fill="kd.b" fill-opacity="1"/>
                        </pattern>

                        <pattern :id="'DRC'+imgid"  width="12.569" height="16.609">
                            <rect height="16.609" width="12.596" :fill="kd.b" fill-opacity="1"/>
                            <polygon points="11.569,0 3.2,16.609 0,10  5,0" :fill="kd.c" fill-opacity="1" />  //white
                            <polygon points="0,10 0,16.609 3.2,16" :fill="kd.c" fill-opacity="1" />  //white
                        </pattern>

                        <pattern :id="'DRCALT'+imgid" patternUnits="objectBoundingBox" width="12.596" height="16.609">
                            <polygon points="24.569,0 0,16.609 0,0" :fill="kd.b" fill-opacity="1" /> 
                            <polygon points="3,16.609 11.596,0 12.596,16.609"  :fill="kd.c" fill-opacity="1"/>
                        </pattern>

                        <pattern :id="'DRL'+imgid"  width="7.298" height="16.609">
                            <rect height="16.609" width="7.2" :fill="kd.b" fill-opacity="1"/> //RED
                            <polygon points="7.9,16.609 4,16.609 7.9,8" :fill="kd.c" fill-opacity="1" /> 
                        </pattern>

                        <pattern :id="'DRBL'+imgid"  width="7.2" height="16.609">
                            <rect height="16.609" width="7.2" :fill="kd.b" fill-opacity="1"/> //RED
                            <polygon points="4.4,0 0,10 0,16.609 4.3,16.609 10,0" :fill="kd.c" fill-opacity="1" /> 
                       
                        </pattern>

                        <pattern :id="'DRBC'+imgid"  width="12.298" height="16.609">
                            <rect height="16.609" width="12.298" :fill="kd.b" fill-opacity="1"/> //RED
                            <polygon points="0,0 3.7,0 0,9" :fill="kd.c" fill-opacity="1" /> 
                       
                        </pattern>




                        <pattern :id="'DR'+imgid"  width="6.298" height="16.609">
                            <polygon points="6.298,0 0,16.609 0,0" :fill="kd.c" fill-opacity="1" /> 
                            <polygon points="-0.5,16.609 7.098,0 7.098,16.609"  :fill="kd.b" fill-opacity="1"/>
                        </pattern>

                        <pattern :id="'DLA'+imgid" patternUnits="objectBoundingBox" width="40" height="40">
                            <polygon points="0,0 0,40 40,40" :fill="kd.c" fill-opacity="1" />
                            <polygon points="40,40 40,0 0,0"  :fill="kd.b" fill-opacity="1"/>
                        </pattern>

                        <pattern :id="'DL'+imgid" patternUnits="objectBoundingBox" width="7.2" height="16.609">
                            <polygon points="0,0 0,16.609 7.2,16.609" :fill="kd.b" fill-opacity="1" />
                            <polygon points="16.609,0 0,0 7.2,16.609"  :fill="kd.c" fill-opacity="1"/>
                        </pattern>

                        <pattern :id="'DLC'+imgid"  width="12.596" height="16.609">
                            <polygon points="0,0 0,16.609 12.596,16.609" :fill="kd.b" fill-opacity="1" />
                            <polygon points="12.596,0 0,0 12.596,16.609"  :fill="kd.c" fill-opacity="1"/>
                        </pattern>

                        <!-- reverse slash -->

                        <pattern :id="'DRR'+imgid"  width="7.2" height="16.609">
                            <polygon points="0,0 0,16.609 7.2,0" :fill="kd.b" fill-opacity="1" />
                            <polygon points="7.2,0 7.2,16.609 0,16.609"  :fill="kd.c" fill-opacity="1"/>
                        </pattern>

                        <pattern :id="'DRRC'+imgid"  width="12.596" height="16.609">
                           <polygon points="12.596,0 12.596,16.609, 0,16.609"  :fill="kd.c" fill-opacity="1"/>
                            <polygon points="0,0 12.596,0 0,16.609" :fill="kd.b" fill-opacity="1" /> 
                        </pattern>

                        <!-- slash over same level-->
                        <pattern :id="'SLL'+imgid"  width="7.2" height="16.609">
                            <polygon points="7.2,0 7.2,16.609 0,16.609"  :fill="kd.c" fill-opacity="1"/>
                            <polygon points="0,0 0,16.609 7.2,10 7.2,0 " :fill="kd.b" fill-opacity="1" />
                            
                        </pattern>

                        <pattern :id="'SLC'+imgid"  width="12.596" height="16.609">
                           <polygon points="0,0 12.596,0  12.596,16.609, 0,16.609"  :fill="kd.c" fill-opacity="1"/>
                            <polygon points="0,0 12.596,0 12.596,5 0,10" :fill="kd.b" fill-opacity="1" /> 
                        </pattern>

                        <pattern :id="'SLR'+imgid"  width="7.2" height="16.609">
                            <polygon points="0,0 7.2,0 7.2,16.609, 0,16.609"  :fill="kd.c" fill-opacity="1"/>
                            <polygon points="0,0 7.2,0 0,5.5" :fill="kd.b" fill-opacity="1" /> 
                  
                        </pattern>

                    </defs>

                   <!-- the below are the individual shapes that make up the shirts  -->
                    <!-- <g >
               
                        <ellipse id="shoulderleft" :fill="kd.g" stroke="grey" transform="rotate(-190.03973388671875 176.5,34.953125) " ry="21.28892" rx="25.86138" cy="34.95312" cx="182.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0.5" />
                        <ellipse id="shoulderright" :fill="kd.i" stroke="grey" transform="rotate(-7.62362289428711 45.50000000000005,32.95312499999998) " ry="21.28892" rx="25.86138" cy="34.95312" cx="45.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0.5" />
                        <rect id="slieveleft" :fill="kd.e" stroke="grey" transform="rotate(15.166099548339844 29.50741577148437,56.437713623046875) " height="37.82882" width="44.60073" y="37.5233" x="14.20704" fill-opacity="null" stroke-opacity="0.1" stroke-width="0.5" />
                        <rect id="slieveright" :fill="kd.k" stroke="grey" transform="rotate(-25.253150939941406 187.50741577148438,53.43771362304687) " height="37.82882" width="44.60073" y="37.5233" x="160.20704" fill-opacity="null" stroke-opacity="0.1" stroke-width="0.5" />
                 
                      
                        <rect id="chesttopleft" :fill="kd.p" height="42" width="42" y="13" x="51.5" fill-opacity="1" />
                        <rect id="chesttopcentre" :fill="kd.s" height="42" width="42" y="13" x="91.5" fill-opacity="1" />
                        <rect id="chesttopright" :fill="kd.u" height="42" width="42" y="13" x="131.5" fill-opacity="1" />

                        <ellipse id="neck" fill="lightgrey" :stroke="kd.o" ry="11.5" rx="41.5" cy="13.95313" cx="108" stroke-opacity="null" stroke-width="5" />

                        <rect id="chestmiddleleft" :fill="kd.w" height="42" width="42" y="53" x="51.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />
                        <rect id="chestmiddlecentre" :fill="kd.y" height="42" width="42" y="53" x="91.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />
                        <rect id="chestmiddleright" :fill="kd.aa" height="42" width="42" y="53" x="131.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />

                        <rect id="chestbottomleft" :fill="kd.ac" height="42" width="42" y="93" x="51.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />
                        <rect id="chestbottomcentre" :fill="kd.ae" height="42" width="42" y="93" x="91.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />
                        <rect id="chestbottomright" :fill="kd.ag" height="42" width="42" y="93" x="131.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />

                        <line id="slievelineleft" fill="none" :stroke="kd.m" y2="81.45313" x2="44.5" y1="72.45313" x1="10.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" />
                        <line id="slievelineright" fill="none" :stroke="kd.n" y2="64.45312" x2="211.5" y1="82.45313" x1="178.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" />

                        
                        <rect id="chestbottomleft" :fill="kd.b" height="5" width="42" y="133" x="51.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />
                        <rect id="chestbottomcentre" :fill="kd.b" height="5" width="42" y="133" x="91.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />
                        <rect id="chestbottomright" :fill="kd.b" height="5" width="42" y="133" x="131.5" fill-opacity="null" stroke-opacity="0.1" stroke-width="0" stroke="grey" />


                    </g> -->

<!-- <path id="neck" fill="lightgreys" d="M612.136,690.202c-1.23-0.667-2.871-1.035-4.62-1.035c-1.749,0-3.39,0.367-4.621,1.035
	c-1.171,0.635-1.842,1.499-1.842,2.371c0,0.873,0.671,1.737,1.842,2.372c1.23,0.667,2.872,1.034,4.621,1.034s3.391-0.367,4.62-1.034
	c1.172-0.635,1.844-1.5,1.844-2.372C613.979,691.701,613.308,690.836,612.136,690.202z"/> -->


    
<path id="slieveright" :fill="kd.k" d="M629.069,707.025c-0.942-2.477-2.724-7.149-3.439-8.956l-0.125-0.316h-5.726v12.083l0.015,0.148l0.055,0.107
	c0.062,0.122,0.223,0.438,0.417,0.858l0.208,0.449l8.763-3.933L629.069,707.025z"/>
<path id="slieveleft" :fill="kd.e" d="M589.551,697.725l-0.125,0.316c-0.715,1.807-2.498,6.479-3.439,8.956l-0.168,0.441l8.764,3.933l0.208-0.449
	c0.211-0.456,0.383-0.791,0.431-0.884l0.056-0.108v-12.205H589.551z"/>
<path id="slievelineright" :fill="kd.m" d="M629.654,708.565l-0.774-2.035l-8.826,3.961l0.212,0.458c0.274,0.594,0.501,1.144,0.674,1.635l0.107,0.305
	l0.322,0.027c0.249,0.021,0.476,0.048,0.747,0.031c1.03,0,2.028-0.175,2.967-0.521c1.197-0.441,2.307-1.117,3.298-2.011
	c0.339-0.306,0.836-0.776,1.232-1.331l0.201-0.28L629.654,708.565z"/>
<path id="slievelineleft" :fill="kd.n" d="M586.175,706.501l-0.407,1.072c-0.244,0.64-0.393,1.033-0.393,1.034l-0.081,0.243l0.148,0.208
	c0.397,0.555,0.894,1.025,1.233,1.331c0.989,0.892,2.099,1.568,3.298,2.01c0.939,0.347,1.937,0.521,2.967,0.521
	c0.25,0,0.499-0.01,0.747-0.031l0.322-0.027l0.107-0.305c0.173-0.491,0.399-1.041,0.674-1.635l0.212-0.458L586.175,706.501z"/>


<path id="shoulderright" :fill="kd.i" d="M625.63,698.07c-0.154-0.391-0.263-0.652-0.304-0.74c-0.152-0.318-0.324-0.678-0.556-1.015
	c-0.314-0.458-0.723-0.88-1.213-1.258c-0.802-0.617-1.793-1.112-3.119-1.558l-0.659-0.221v5.476h6.122L625.63,698.07z"/>
<path id="shoulderleft" :fill="kd.g" d="M594.617,693.471c-1.327,0.446-2.318,0.941-3.12,1.558c-0.49,0.377-0.897,0.8-1.211,1.258
	c-0.231,0.336-0.404,0.696-0.556,1.014c-0.042,0.086-0.149,0.349-0.304,0.74l-0.271,0.684h6.121v-5.475L594.617,693.471z"/>


<path id="chesttopleft" :fill="kd.p" d="M602.031,694.919c-0.642-0.472-1.407-1.263-1.407-2.347c0-0.13,0.011-0.258,0.033-0.384l0.095-0.587h-0.732
	l-0.117,0.069c-0.225,0.132-0.458,0.25-0.695,0.358c-0.731,0.333-1.511,0.568-2.246,0.772l-0.625,0.171
	c-0.582,0.158-1.161,0.315-1.717,0.5l-0.343,0.114v14.625h7.958v-13.14L602.031,694.919z"/>    
<path  id="chesttopcentre" :fill="kd.s" d="M613.031,694.915c-1.347,0.96-3.376,1.511-5.566,1.511c-2.154,0-4.136-0.549-5.437-1.506l-0.796-0.586v13.89
	h12.589v-13.872L613.031,694.915z"/>
<path id="chesttopright" :fill="kd.u" d="M620.437,693.499c-0.559-0.185-1.139-0.343-1.723-0.501l-0.62-0.17c-0.735-0.204-1.516-0.438-2.246-0.771
	c-0.237-0.108-0.47-0.226-0.695-0.358l-0.126-0.068h-0.731l0.104,0.587c0.022,0.126,0.033,0.254,0.033,0.384
	c0,1.084-0.765,1.875-1.406,2.347l-0.204,0.15v13.14h7.958v-14.625L620.437,693.499z"/>




<path id="chestmiddleleft" :fill="kd.w" d="M594.276,707.209l0.027,4.527l0.433-0.803c0.494,1.511,0.619,3.266,0.698,4.959
	c0.104,2.183-0.004,4.336-0.146,6.724l-0.075,1.202h7.021v-16.609H594.276z"/>
<rect id="chestmiddlecentre" :fill="kd.y" x="601.235" y="707.209"  width="12.596" height="16.609"/>
<path id="chestmiddleright" :fill="kd.aa" d="M612.821,707.238v16.609h7.02l-0.073-1.202c-0.142-2.385-0.25-4.537-0.146-6.724
	c0.079-1.688,0.203-3.438,0.695-4.948l0.464,0.876v-4.612H612.821z"/>   



<path id="chestbottomleft" :fill="kd.ac" d="M595.277,722.818l-0.029,0.469c-0.119,1.905-0.263,3.81-0.407,5.713l-0.06,0.785
	c-0.043,0.573-0.095,1.148-0.147,1.725c-0.17,1.886-0.346,3.835-0.219,5.763l0.015,0.229l0.185,0.138
	c0.648,0.484,1.404,0.847,2.31,1.109c0.683,0.2,1.447,0.342,2.406,0.446c0.317,0.034,0.632,0.062,0.944,0.087
	c0.464,0.038,0.929,0.065,1.419,0.085l0.54,0.05v-16.599H595.277z"/>    
<path id="chestbottomcentre" :fill="kd.ae" d="M601.235,722.818v16.53l0.479,0.021c0.769,0.032,1.538,0.043,2.308,0.046l0.389,0.001
	c0.516,0,1.032-0.004,1.549-0.007l1.558-0.008l1.678,0.009c0.513,0.004,1.025,0.007,1.538,0.007h0.25
	c0.78-0.002,1.56-0.014,2.349-0.048h0.5v-16.551H601.235z"/>

<path id="chestbottomright" :fill="kd.ag" d="M620.423,731.541c-0.053-0.577-0.104-1.153-0.147-1.727l-0.065-0.861c-0.142-1.878-0.284-3.757-0.401-5.638
	l-0.029-0.469h-6.958v16.051l0.031,0.521l0.521-0.021c0.469-0.021,0.938-0.048,1.407-0.086c0.312-0.025,0.626-0.053,0.945-0.087
	c0.958-0.104,1.722-0.246,2.404-0.445c0.906-0.264,1.662-0.627,2.31-1.11l0.185-0.138l0.016-0.229
	C620.769,735.374,620.593,733.426,620.423,731.541z"/>


    <path id="neck" fill="lightgrey" :stroke="kd.o" d="M607.504,687.721c-4.418,0-7.88,2.131-7.88,4.852c0,2.721,3.461,4.853,7.88,4.853
	c4.52,0,7.928-2.086,7.928-4.853C615.432,689.852,611.949,687.721,607.504,687.721z"  /> 
                


                    </svg>
            </div>

        </div>
        </template>


<script>

  



    export default {
        name: "kit",
        props: {
            vmagnify: { type: Number, default: 1 },
            hmagnify: { type: Number, default: 1 },
            teamname: { type: String },
            sport: {type:String, default: ""},
            country: {type:String, default: "Unknown"},
            
           
        },
        data: function () {
            return {
                kd: [],
                vsize: 184.09,
                hsize: 225,
                imgid: '',
                initialload: false,
                kitdetails: [],
                kitloaded: false,
                flagloaded: false
            }
        },
        components: {
                //basicshirt: basicshirt
        },
        computed: {
            hints () {
                return this.$store.state.showhints;
            }

        },
        mounted() {


            this.flagloaded = false;

            const availshirts = ["Soccer", "RugbyUnion"];

            if (availshirts.includes(this.sport)) {
                
                let url = process.env.VUE_APP_DATA + this.sport + " kitdetails.json"
         
                this.localJSONLoad(url).then(response => {
                
                    this.kitdetails = response
                    this.getTeamKit();
                   
                    }).catch(error => console.log(error));
            } else {

                //need to go and find the flag for each competot
                this.flagloaded = true;
              
            } 
             
        },

          watch: {
    
            teamname: function (valold,valnew) {
               
                this.getTeamKit();
            }
        },

        methods: {


            getTeamKit: function () {
            
                let self = this;

               var shapeprefix = "url(#"
               var shapesuffix = ")"

                    var kitdata = self.kitdetails.filter(function (kit) {
                        return kit.a === self.teamname;
                    });
                    


                    //if no kit returned
                    if (kitdata.length == 0) {
                        
                        //no kit returned see if there is a flag for the country (if country not null)
                        //  if (self.country.length > 0) {
                           
                        //      self.flagloaded = true;
                        //      return;
                        //  }


                        kitdata = this.kitdetails.filter(function (kit) {
                        return kit.a === 'Default';
                    });
                    }


                    self.kd = kitdata[0];

                    self.imgid = self.kd["a"].replace(/\s/g, "");


                    //iterate through elements of kit data
                    Object.keys(self.kd).forEach(function (key) {
       
                        //does the property have a corresponding pattern value
                       if (self.kd[key] == '') { self.kd[key] = 'grey'}

                        if (Object.prototype.hasOwnProperty.call(self.kd, key + "pat")) {
                            //if no colour then select light grey
                         
                            if (self.kd[key + "pat"] != 'P' && self.kd[key + "pat"] != '') {
                         
                                self.kd[key] = shapeprefix + self.kd[key + "pat"] + self.imgid + shapesuffix;
                            }
                        }
                    });


                    //add magnification props
                if (self.initialload == false) {    
                    self.imgid = self.kd["a"].replace(/\s/g, "")
                    self.vsize = self.vsize / self.vmagnify;
                    self.hsize = self.hsize / self.hmagnify;
                    self.initialload = true;
                }

                self.kitloaded = true
            }
        }



 
    }

</script>